import { useCallback, useEffect } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import Mixpanel from 'services/tracking';
import ScreeningFlow from './components/ScreeningFlow';
import { TEST_ID } from './constants';
import { ScreeningFlowProvider } from 'providers/ScreeningFlowProvider';
import { useTranslation } from 'react-i18next';
import { useConfiguration } from 'providers/ConfigurationProvider';

const cookieFallback = 'blodtryckstest';

function App() {
  const { t } = useTranslation();
  const { partnerName } = useConfiguration();

  const handleAcceptCookie = useCallback(() => {
    Mixpanel.init(partnerName && TEST_ID ? { partnerName, testId: TEST_ID } : undefined);
  }, [partnerName]);

  useEffect(() => {
    const hasConsent = getCookieConsentValue(TEST_ID || cookieFallback);
    if (hasConsent === 'true') {
      handleAcceptCookie();
    }
  }, [handleAcceptCookie]);

  return (
    <ScreeningFlowProvider>
      <main>
        <ScreeningFlow />
        <CookieConsent
          cookieName={TEST_ID || cookieFallback}
          overlay
          enableDeclineButton
          // debug={process.env.NODE_ENV !== 'production'}
          onAccept={handleAcceptCookie}
          buttonText={t('cookie_notice.accept')}
          declineButtonText={t('cookie_notice.decline')}
          buttonWrapperClasses="cookie-buttons-wrapper"
          style={{
            backgroundColor: '#fae9ee',
            color: '#710539',
            minHeight: '10vh',
            display: 'flex',
            alignItems: 'center'
          }}
          buttonStyle={{ backgroundColor: '#710539', color: '#fae9ee', borderRadius: '100px', padding: '10px 20px' }}
          declineButtonStyle={{
            backgroundColor: '#710539',
            color: '#fae9ee',
            borderRadius: '100px',
            padding: '10px 20px'
          }}
        >
          {t('cookie_notice.info_1')}{' '}
          <a href="https://blodtrycksdoktorn.se/cookies/" target="_blank" rel="noreferrer">
            {t('cookie_notice.info_2')}
          </a>
          .
        </CookieConsent>
      </main>
    </ScreeningFlowProvider>
  );
}

export default App;
